<template>
	<v-bottom-navigation grow app class="d-flex justify-between align-center">
		<template v-if="authenticated && setupInfo.joinedCompany">
			<v-btn text small plain :to="{ name: 'Company' }">
				<span>{{ $t('companies.company') }}</span>
				<v-icon>mdi-domain</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Offers' }">
				<span>{{ $t('offers.offers') }}</span>
				<v-icon>mdi-briefcase-outline</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Events' }">
				<span>{{ $t('events.events') }}</span>
				<v-icon>mdi-calendar</v-icon>
			</v-btn>

			<v-btn text small plain :to="{ name: 'Messages' }">
				<span>{{ $t('nav.messages') }}</span>
				<v-icon>mdi-forum</v-icon>
			</v-btn>
		</template>

		<v-btn text small plain @click.stop="opened ? close() : open()">
			<span>{{ $t('nav.menu') }}</span>
			<v-icon>mdi-menu</v-icon>
		</v-btn>
	</v-bottom-navigation>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'BottomNavbar',
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			setupInfo: 'user/setupInfo',
			opened: 'sidebar/opened'
		})
	},
	methods: {
		...mapActions('sidebar', ['open', 'close'])
	}
}
</script>
